
import { defineComponent, reactive, onMounted } from "vue";
import Footer from "@/components/home/Footer.vue";



export default defineComponent({
  components: {Footer},
  setup() {
    return {};
  },
});
